import React from 'react';
import hotelbooking from '../images/hotelbooking.webp'
import visa from '../images/visa.webp'
import visitvisa from '../images/visitvisa.webp'
import airticketing from '../images/airticketing.webp'
import carinsurance from '../images/carinsurance.png'
import domesticvisa from '../images/domesticvisa.png'
import notarypublic from '../images/notarypublic.png'
import translationservices from '../images/translationservices.png'

const services = [
    { 
        id: 1, 
        title: 'Visit Visa', 
        description: 'Organise tours and take you there.', 
        image:visitvisa,
        icon: '👥' // Busts in Silhouette Emoji
    },
    { 
        id: 2, 
        title: 'Air Ticket Booking', 
        description: 'Book your air tickets with ease.', 
        image:airticketing,
        icon: '✈️' // Airplane Emoji
    },
    { 
        id: 3, 
        title: 'Hotel Booking', 
        description: 'Find and book hotels for you.', 
        image:hotelbooking,
        icon: '🏨' // Hotel Emoji
    },
    { 
        id: 4, 
        title: 'Resident Visa Services', 
        description: 'Travel and Resident Visa assistance.',
        image:visa,
        icon: '🛂' // Passport Control Emoji
    },
    { 
      id: 5, 
      title: 'Domestic Workers', 
      description: 'Organise tours and take you there.', 
      image:domesticvisa,
      icon: '👥' // Busts in Silhouette Emoji
    },
    { 
      id: 6, 
      title: 'Translation & Attestation', 
      description: 'We offer Translation Services for all languages and Attestation of Documents.', 
      image:translationservices,
      icon: '📜' // Scroll Emoji
    },
    { 
      id: 7, 
      title: 'Business Setup Trading License', 
      description: 'Assist with setting up businesses and obtaining trading licenses.', 
      image:hotelbooking,
      icon: '🏢' // Office Building Emoji
    },
    { 
      id: 8, 
      title: 'Notary Public', 
      description: 'Notarize your documents and provide legal certification.', 
      image:notarypublic,
      icon: '✍️' // Writing Hand Emoji
    },
    { 
      id: 9, 
      title: 'Vehicle Insurances', 
      description: 'Provide vehicles insurances on cheapest prices', 
      image:carinsurance,
      icon: '🚗' // Car Emoji
    }
  ];
  

const ServicesSection = () => {
  return (
    <div className="bg-gray-100 p-8">
      <div className="max-w-7xl mx-auto">
        {/* Heading and Description */}
        <div className="text-center mb-8 py-20">
          <h1 className="text-4xl font-bold text-gray-800 mb-4">OUR SERVICES</h1>
          <p className="text-lg text-gray-600">
            We offer a wide variety of services. We provide both Travel and Resident Visa, 
            Find and book Hotels for our Clients, Organise Tours and Take you there, 
            Booking of Air Tickets
            , Also we offer Other Typing Services. 
            {/* Services such as Money Exchange and offering advice. */}
          </p>
        </div>

        {/* Services Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {services.map((service) => (
            <div
              key={service.id}
              className="bg-white p-6 rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105 hover:shadow-xl flex flex-col items-center">
              <div className="mb-4">
              <img
                    src={service.image}
                    alt={service.title}
                    className="w-50 h-50 object-cover  mb-2"
              />
                {/* <div className="text-3xl mb-2">{service.icon}</div> */}
              </div>
              <h2 className="text-xl font-bold mb-2 text-center">{service.title}</h2>
              <p className="text-gray-700 text-center">{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServicesSection;

// src/components/Footer.js
import React from 'react';
import whatsapp from '../images/icons/whatsapp.png'

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-4">
      <div className="container mx-auto flex justify-around items-center">
        <div>
          <p className="text-sm">&copy; {new Date().getFullYear()} Freelanceuaevisa</p>
        </div>
        <div className="space-x-4">
          {/* <a href="#" className="hover:underline">Home</a> */}
          {/* <a href="#" className="hover:underline">About</a>
          <a href="#" className="hover:underline">Contact</a> */}
           <div className="flex flex-col">
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://wa.me/971509297124/?text=I am Interested to know more about 2 Years Freelance Visa Package...!"
                className="flex items-center bg-white rounded-md p-2"
            >
            <img width="20" height="20" src={whatsapp} alt="WhatsApp" />
            <div class="flex flex-col items-center">
              <div className="ml-2 text-sm  text-black">+971-509297124</div>
            </div>
          </a>
        </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

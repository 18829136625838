// src/components/ColoredBoxes.js

import React from 'react';

const ColoredBoxes = () => {
  return (
    <div className="flex flex-col md:flex-row md:space-x-4 p-4">
      <div className="bg-gray-500 text-white p-4 rounded-md flex-1 mb-4 md:mb-0">
        <h2 className="text-xl font-bold">Step 01</h2>
        <p>Tell us about your requirements</p>
      </div>
      <div className="bg-gray-500 text-white p-4 rounded-md flex-1 mb-4 md:mb-0">
        <h2 className="text-xl font-bold">Step 02</h2>
        <p>Send documents</p>
      </div>
      <div className="bg-gray-500 text-white p-4 rounded-md flex-1 mb-4 md:mb-0">
        <h2 className="text-xl font-bold">Step 03</h2>
        <p>Receive the service within short time</p>
      </div>
    </div>
  );
};

export default ColoredBoxes;

export default function AnimatedText(){
    return (
    <   div class="w-full max-w-5xl mx-auto px-4 md:px-6 py-24">
                <div class="text-center">
                    {/* <!-- Sliding Text animation --> */}
                    <div class="justify-center font-extrabold text-3xl md:text-4xl [text-wrap:balance] bg-clip-text  bg-gradient-to-r text-black">Trusted by the most innovative minds in <span class="text-indigo-500 inline-flex flex-col h-[calc(theme(fontSize.3xl)*theme(lineHeight.tight))] md:h-[calc(theme(fontSize.4xl)*theme(lineHeight.tight))] overflow-hidden">
                        <ul class="justify-center items-center max-w-5xl md:text-left lg:text-left text-center block animate-text-slide-5 leading-tight [&_li]:block">
                            <li>Business Setup Services</li>
                            <li>Family Visa Services</li>
                            <li>Vehicle Insurance</li>
                            <li>Translation Services</li>
                            <li>Notary Public Services</li>
                            <li aria-hidden="true">Domestic Visa Services</li>
                        </ul>
                    </span>   
                </div>
                    {/* <!-- End: Sliding Text animation --> */}
            </div>
    
         </div>
)
}
// src/components/Menu.js
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import whatsapp from '../images/icons/whatsapp.png'
import logo from '../images/icons/logo.png'
const Menu = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const location = useLocation();
  const toggleMenu = () => setIsOpen(!isOpen);
  const isActive = (path) => location.pathname === path;

  return (
    <>
      <header className="bg-white text-white">
        <nav className="container mx-auto p-4 flex items-center justify-between">
          {/* Left section with site name */}
          <div className="flex items-center flex-grow lg:flex-grow-0">
            <div className="text-lg font-bold">
              <Link to="/"><img width="150" height="150" src={logo}></img></Link>
            </div>
          </div>
          {/* Centered WhatsApp contact on mobile screens */}
          <div className="lg:hidden h-10 flex-grow flex justify-center items-center">
          <div className="flex flex-col">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://wa.me/971509297124/?text=I am Interested to know more about 2 Years Freelance Visa Package...!"
              className="flex items-center bg-white rounded-md p-2"
            >
              <img width="20" height="20" src={whatsapp} alt="WhatsApp" />
              <div class="flex flex-col items-center">
                <div className="ml-2 text-sm  text-black">+971-509297124</div>
              </div>
          </a>
        </div>
        </div>

          {/* Centered menu on large screens */}
          {/* <div className="hidden lg:flex lg:items-center lg:justify-center flex-grow space-x-4">
            <Link
              to="/"
              className={`px-4 py-2 rounded-md text-sm font-medium ${
                isActive('/') ? 'bg-gray-700 text-white' : 'hover: bg-gray-400 hover:text-white'
              }`}
            >
              Home
            </Link>
           <Link
              to="/about"
              className={`px-4 py-2 rounded-md text-sm font-medium ${
                isActive('/about') ? 'bg-gray-700 text-white' : 'hover: bg-gray-400 hover:text-white'
              }`}
            >
              About
            </Link>
            <Link
              to="/services"
              className={`px-4 py-2 rounded-md text-sm font-medium ${
                isActive('/services') ? 'bg-gray-700 text-white' : 'hover: bg-gray-400 hover:text-white'
              }`}
            >
              Services
            </Link>
            <Link
              to="/contact"
              className={`px-4 py-2 rounded-md text-sm font-medium ${
                isActive('/contact') ? 'bg-gray-700 text-white' : 'hover: bg-gray-400 hover:text-white'
              }`}
            >
              Contact
            </Link> 
          </div> */}

          {/* WhatsApp contact link on large screens */}
          <div className="hidden lg:flex lg:items-center lg:ml-auto">
           
			  <div className="flex flex-col">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://wa.me/971509297124/?text=I am Interested to know more about 2 Years Freelance Visa Package...!"
            className="flex items-center bg-white rounded-md p-2"
          >
            <img width="20" height="20" src={whatsapp} alt="WhatsApp" />
            <div class="flex flex-col items-center">
            <div className="ml-2 text-sm text-black">WhatsApp Us</div>
              <div className="ml-2 text-sm  text-black">+971-509297124</div>
            </div>
          </a>
        </div>
        </div>

          {/* Menu button for mobile */}
          {/* <button
            className="lg:hidden px-3 py-2 rounded-md text-gray-200 hover:text-white focus:outline-none"
            onClick={toggleMenu}>
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={isOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16M4 18h16'}
              />
            </svg>
          </button> */}
        </nav>
      </header>

      {/* Sidebar menu for mobile */}
      <div
        className={`fixed inset-y-0 left-0 w-64 bg-gray-800 text-white transform transition-transform duration-300 ease-in-out z-50 ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        } lg:hidden`}
      >
        <div className="p-4 space-y-4">
          <Link
            to="/"
            className={`block px-4 py-2 rounded-md text-sm font-medium ${
              isActive('/') ? 'bg-gray-700 text-white' : 'hover: bg-gray-400 hover:text-white'
            }`}
            onClick={() => setIsOpen(false)}
          >
            Home
          </Link>
          <Link
            to="/about"
            className={`block px-4 py-2 rounded-md text-sm font-medium ${
              isActive('/about') ? 'bg-gray-700 text-white' : 'hover: bg-gray-400 hover:text-white'
            }`}
            onClick={() => setIsOpen(false)}
          >
            About
          </Link>
          <Link
            to="/services"
            className={`block px-4 py-2 rounded-md text-sm font-medium ${
              isActive('/services') ? 'bg-gray-700 text-white' : 'hover: bg-gray-400 hover:text-white'
            }`}
            onClick={() => setIsOpen(false)}
          >
            Services
          </Link>
          <Link
            to="/contact"
            className={`block px-4 py-2 rounded-md text-sm font-medium ${
              isActive('/contact') ? 'bg-gray-700 text-white' : 'hover: bg-gray-400 hover:text-white'
            }`}
            onClick={() => setIsOpen(false)}
          >
            Contact
          </Link> 
        </div>
      </div>

      {/* Background overlay when menu is open */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-40"
          onClick={() => setIsOpen(false)}
        />
      )}
    </>
  );
};

export default Menu;
import React from 'react';
import whyus from '../images/whyus.jpg'

const VisaProcessing = () => {
  return (
    <div className="flex items-center justify-center h-1/6 p-6 bg-gray-100">
      <div className="flex flex-col lg:flex-row items-center justify-center max-w-7xl mx-auto">
        {/* Text Section */}
        <div className="flex-1 lg:pr-6 flex flex-col items-center lg:items-start text-center lg:text-left mb-6 lg:mb-0">
          <h2 className="text-2xl font-bold mb-4">Why Choose Us</h2>
          <p className="mb-4">Experience hassle-free visa processing with us today.</p>

          <h3 className="text-xl font-semibold mb-2">Flexible Pricing</h3>
          <p className="mb-4">Experience flexibility like never before with our transparent and adaptable pricing structure.</p>

          <h3 className="text-xl font-semibold mb-2">Professional Staff</h3>
          <p>With a team of experienced professionals dedicated to simplifying the visa process, we ensure efficiency, reliability, and customer satisfaction.</p>
        </div>

        {/* Image Section */}
        <div className="flex-1 lg:w-1/2 flex justify-center">
          <img 
            src={whyus} 
            alt="Visa Processing" 
            className="w-full h-auto object-cover rounded-lg shadow-lg" 
          />
        </div>
      </div>
    </div>
  );
};

export default VisaProcessing;
import bg from '../images/wallpaper.jfif'
import AnimatedText from '../shared/animatedtext';
import sponsorshipicon from '../images/icons/sponsorship.png'
import hiddencosticon from '../images/icons/no_hidden_cost.png'
import hiringicon from '../images/icons/hiring.png'
import visaicon from '../images/icons/visa.png'
import FreelancePackageDetails from './freelancepackagedetails';
import ColoredBoxes from './instructions';
import Footer from '../shared/footer';
import VisaProcessing from '../shared/whyus';
import ServicesGrid from './services';

const Home = () => {
  const details = [
    { icon: sponsorshipicon, title: "Under Our Sponsorship" },
    { icon: hiddencosticon, title: "No Hidden Cost" },
    { icon: hiringicon, title: "NOC Letter" },
    { icon: visaicon, title: "Family Sponsorship" }
  ];

  return (
    <>
      <div className="relative w-full">
        {/* Background Image */}
       <div className="relative">
          <img 
            src={bg} 
            alt="Background" 
            className="object-cover w-full h-full" 
            style={{ height: '600px' }}  // Specify the height of the image here
          />
        </div>

        {/* Overlay */}
        <div className="absolute inset-0 bg-slate-500 bg-opacity-50 flex items-center justify-center">
          <div className="text-center text-white px-4 py-8">
            <h1 className="text-6xl font-bold mb-4">Freelance UAE Visa</h1>
            <p className="text-2xl mb-10">2 Years Freelance Visa Starting From 7500 AED</p>
            {/* Details Section */}
            <div className="flex flex-wrap justify-center gap-4">
              {details.map((item, index) => (
                <div 
                  key={index} 
                  className="flex flex-col items-center p-4 rounded-lg border-2 bg-black bg-opacity-80 shadow-md"
                >
                  <img 
                    width="45" 
                    height="45" 
                    src={item.icon} 
                    alt={item.title} 
                    className="mb-2" 
                  />
                  <div className="text-center text-xs">{item.title}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
       </div>
       <AnimatedText/>
        <ServicesGrid/>
        {/* <ColoredBoxes/>  */}
        {/* <FreelancePackageDetails class="mb-10"/> */}
        <VisaProcessing  /> 
      <Footer className="bottom-0 left-0 right-0" />
    </>
  );
}

export default Home;
